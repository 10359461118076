import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const confirmation=(title,message,submitHandler)=>{

 confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: 'Yes',
            onClick: submitHandler
          },
          {
            label: 'No',
          }
        ]
      });


}

export default confirmation